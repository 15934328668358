/// auth service

export const LOGIN = "https://api.hotelly.net/api/v1/admin/login";

export const CHANGE_PASSWORD = "admin/changePass";

export const LOGOUT = "admin/auth/logout";

export const DASHBOARD = "admin/dashboard";
export const CREATE_GROUP = "admin/group";

export const GROUP_LIST = "admin/group";

export const CREATE_HOTEL_OWNER = "admin/addAdmin";

export const EDIT_HOTEL_OWNER = "admin/editAdmin";

export const ADMIN_LIST = "admin/adminslist";
export const DELETE_ADMIN = "admin/deleteAdmin";
export const PROPERTY_LIST = "admin/hotel";
export const DELETE_GROUP = "admin/group";
export const CREATE_PROPERTY = "admin/hotel";
export const DELETE_PROPERTY = "admin/hotel";

export const GROUP_ID = "admin/get_groups";

export const HOTEL_OWNER_LIST = "admin/listOwners";

export const HOTEL_ASSIGN_TO_HOTEL_OWNER = "admin/assignHotel";

export const CATEGORY_LIST = "admin/category";

export const DELETE_CATEGORY = "admin/category";

export const CREATE_CATEGORY = "admin/category";


