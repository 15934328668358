

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { createCategory } from "../../services/AuthService";

import "react-phone-number-input/style.css";

function CreateCategory({ addCategoryShow, onHide, hotelOwnerListCall }) {
  const [name, setName] = useState("");
  const [errors, setErrors] = useState("");
  const [loader, setLoader] = useState(false);

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success} !`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const notifyError = (error) => {
    toast.error(`❌ ${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const validateName = (name) => {
    if (!name.trim()) {
      return "Name is required and cannot be empty";
    }
    return "";
  };

  function onAddCategory(e) {
    e.preventDefault();
    setLoader(true);

    const error = validateName(name);
    if (error) {
      setErrors(error);
      setLoader(false);
      return;
    }

    createCategory(name.trim())
      .then((response) => {
        setName("");
        setErrors("");
        hotelOwnerListCall();
        setLoader(false);
        onHide();
        notifyTopRight(response.data.responseMessage);
      })
      .catch((error) => {
        setLoader(false);
        setErrors("");
        notifyError(error.response.data.responseMessage);
      });
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrors(""); // Clear the error when user types
  };

  return (
    <>
      <Modal className="modal fade" show={addCategoryShow}>
        <div className="" role="document">
          <div className="">
            <form onSubmit={onAddCategory}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Create Category</h4>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-dismiss="modal"
                  onClick={() => onHide()}
                >
                  <span>
                    {" "}
                    <strong>X</strong>{" "}
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Category Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="name"
                          value={name}
                          onChange={handleNameChange} // Use updated handler
                          placeholder="Enter category name"
                          required
                        />
                        <span className="validation-text text-danger">{errors}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loader}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default CreateCategory;
