import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Switch from "react-switch";
import {
  createProperty,
  editProperty,
  getGroupId,
} from "../../services/AuthService";
import CreateHotelGroup from "./CreateHotelGroup";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

// import { createHotel } from "../../services/HotelService"; // Import the relevant service
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

function CreateProperty({
  addPropertiesModelShow,
  onHide,
  callHotelList,
  editMode,
  propertyData,
}) {
  console.log(propertyData, "propertyData");
  const initialFormState = {
    hotel_name: editMode ? propertyData.hotel_name : "",
    entity_name: editMode ? propertyData.entity_name : "",
    group_id: editMode ? propertyData.group_id : "",
    address: editMode ? propertyData.address : "",
    suburb: editMode ? propertyData.suburb : "",
    city: editMode ? propertyData.city : "",
    postcode: editMode ? propertyData.postcode : "",
    state: editMode ? propertyData.state : "",
    country: editMode ? propertyData.country : "",

    rooms: editMode ? propertyData.rooms : "",
    billed_devices: editMode ? propertyData.billed_devices : "",
    spares: editMode ? propertyData.spares : "",
    prestage_enrollment: editMode ? propertyData.prestage_enrollment : "",
    jamf_Site_name: editMode ? propertyData.jamf_Site_name : "",
    jamf_building_name: editMode ? propertyData.jamf_building_name : "",
    pms_id: editMode ? propertyData.pms_id : "",
    contact_number: editMode ? propertyData.contact_number : "",
    contact_email: editMode ? propertyData.contact_email : "",
    contact_firstname: editMode ? propertyData.contact_firstname : "",
    contact_lastname: editMode ? propertyData.contact_lastname : "",
    billing_firstname: editMode ? propertyData.billing_firstname : "",
    billing_lastname: editMode ? propertyData.billing_lastname : "",
    billing_phone_number: editMode ? propertyData.billing_phone_number : "",
    billing_email: editMode ? propertyData.billing_email : "",
    gst: editMode ? propertyData?.gst : false,
    tax_number: editMode ? propertyData?.tax_number : "",
    country_code: editMode ? propertyData?.country_code : "+91",
    finance_country_code: editMode ? propertyData?.finance_country_code : "+91",
    lat: editMode ? propertyData.lat : 0,
    long: editMode ? propertyData.long : 0,
  };

  const [formState, setFormState] = useState(initialFormState);
  console.log(formState, "999");
  const [error, setError] = useState("");
  const [errorBilling, setErrorBilling] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorTaxNumber, setErrorTaxNumber] = useState("");

  const [groupName, setGroupName] = useState([]);
  const [loader, setLoader] = useState(false);
  const [requiredErrors, setRequiredErrors] = useState("");
  const [addHotelGroupShow, setAddHotelGroupShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [gst, setGst] = useState((propertyData && propertyData?.gst) || false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    (propertyData && propertyData?.contact_number) || ""
  );
  console.log(selectedCountryCode, "vvv");
  const [selectedFinanceCountryCode, setSelectedFinanceCountryCode] = useState(
    (propertyData && propertyData?.billing_phone_number) || ""
  );
  const [googleAddress, setGoogleAddress] = useState(
    (propertyData && propertyData?.address) || ""
  );

  const handleChangeSwitch = (nextChecked) => {
    setChecked(nextChecked);
  };
  const handleGst = (nextChecked) => {
    setGst(nextChecked);
    setFormState({
      ...formState,
      gst: editMode ? propertyData.gst : nextChecked,
    });
  };
  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event);
    setFormState({
      ...formState,
      contact_number: editMode ? propertyData.contact_number : event,
    });
  };
  const handleFinanceCountryCodeChange = (event) => {
    setSelectedFinanceCountryCode(event);
    setFormState({
      ...formState,
      billing_phone_number: editMode
        ? propertyData.billing_phone_number
        : event,
    });
  };
  useEffect(() => {
    if (!editMode) {
      if (checked) {
        setFormState({
          ...formState,
          billing_firstname: selectedGroup
            ? selectedGroup.finance_firstname
            : "",
          billing_lastname: selectedGroup ? selectedGroup.finance_lastname : "",
          billing_phone_number: selectedGroup
            ? selectedGroup.finance_number
            : "",
          billing_email: selectedGroup ? selectedGroup.contact_email : "",
          // finance_country_code: selectedGroup
          //   ? selectedGroup.finance_country_code
          //   : "",
          tax_number: selectedGroup ? selectedGroup.tax_number : "",
          gst: selectedGroup ? selectedGroup.gst : false,
        });
        setSelectedFinanceCountryCode(
          selectedGroup ? selectedGroup.finance_number : ""
        );
        setGst(selectedGroup ? selectedGroup.gst : false);
      } else {
        setFormState({
          ...formState,
          billing_firstname: "",
          billing_lastname: "",
          // billing_phone_number: "",
          billing_email: "",
          // finance_country_code: "",
          tax_number: "",
          gst: "",
        });
        setSelectedFinanceCountryCode("");
        setGst(false);
      }
    }
  }, [checked]);
  // console.log(selectedGroup, "selectedGroup");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "group_id") {
      // Find the selected group from the list
      const selectedGroupItem = groupName.find(
        (item) => item.id === parseInt(value)
      );

      setSelectedGroup(selectedGroupItem);
      // setFormState((prev) => ({ ...prev, [name]: value }));
    }
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success} !`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleAddress = (place) => {
    let city = "";
    let zipCode = "";
    let suburb = "";
    let state = "";
    let country = "";
    setGoogleAddress(place.label);

    geocodeByAddress(place.label)
      .then((results) => {
        const addressComponents = results[0]?.address_components || [];

        city =
          addressComponents.find(
            (component) =>
              component.types.includes("locality") ||
              component.types.includes("administrative_area_level_2")
          )?.long_name || "";

        suburb =
          addressComponents.find((component) =>
            component.types.includes("sublocality")
          )?.long_name || "";

        state =
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || "";

        country =
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name || "";

        // Find the zip code from address components
        zipCode =
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "";

        return getLatLng(results[0]);
      })
      .then((res) => {
        setFormState((prevLocation) => ({
          ...prevLocation,
          address: place.label,
          suburb: suburb,
          city: city,
          postcode: zipCode,
          state: state,
          country: country,
          lat: res.lat,
          long: res.lng,
        }));
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    if (editMode) {
      // Remove leading and trailing spaces before API call

      // Remove leading and trailing spaces before API call
      const trimmedFormState = Object.fromEntries(
        Object.entries(formState).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );

      editProperty(trimmedFormState, propertyData.id)
        .then((response) => {
          console.log(response, "oo");
          setLoader(false);
          onHide();
          notifyTopRight(response.data.responseMessage);
          callHotelList();
        })
        .catch((error) => {
          setLoader(false);
          console.log(error.response, "error");
          // setApiError(error.response.data.message);
          notifyError(error.response?.data?.responseMessage);
          console.log(error.response.data.responseMessage, "pp");
        });
    } else {
      // const requiredFields = [
      //   "hotel_name",
      //   "group_id",
      //   "address",
      //   "postcode",
      //   "suburb",
      //   "city",
      //   "state",
      //   "country",
      //   "rooms",
      //   "billed_devices",
      //   "spares",
      //   "entity_name",
      //   "contact_firstname",
      //   "contact_lastname",
      //   "contact_number",
      //   "contact_email",
      //   "prestage_enrollment",
      //   "jamf_Site_name",
      //   "jamf_building_name",
      //   "pms_id",
      //   "billing_firstname",
      //   "billing_lastname",
      //   "billing_phone_number",
      //   "billing_email",
      //   "tex_number",
      // ];

      // if (
      //   Object.entries(formState).some(([key, value]) => {
      //     const isRequiredField = requiredFields.includes(key);
      //     return isRequiredField && (!value || value.trim() === "");
      //   })
      // ) {
      //   setRequiredErrors("All fields are required, except GST");
      //   setLoader(false);
      //   return;
      // }
      if (selectedCountryCode && selectedCountryCode.length === 0) {
        setError(`Contact number is required`);
        return;
      }
      if (
        selectedCountryCode &&
        (selectedCountryCode.length < 6 || selectedCountryCode.length > 15)
      ) {
        setError(
          `Length must be between 6 and 15 characters for Contact number`
        );
        return;
      }
      if (
        selectedFinanceCountryCode &&
        (selectedFinanceCountryCode.length < 6 ||
          selectedFinanceCountryCode.length > 15)
      ) {
        setErrorBilling(
          `Length must be between 6 and 15 characters for Billing phone number`
        );

        return;
      }

      if (googleAddress === "") {
        setErrorAddress(`Address is required`);

        return;
      }
      if (!formState.tax_number || formState.tax_number.trim() === "") {
        setErrorTaxNumber("Tax Number is required");
        setLoader(false);
        return;
      }

      // Remove leading and trailing spaces before API call

      const trimmedFormState = Object.fromEntries(
        Object.entries(formState).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );
      const apiData = {
        ...trimmedFormState,
      };
      console.log(formState, "formState");
      console.log(apiData, "apiData");
      createProperty(apiData)
        .then((response) => {
          console.log(response, "oo");
          setFormState(initialFormState);
          setError("");
          setErrorBilling("");
          setLoader(false);
          onHide();
          notifyTopRight(response.data.responseMessage);
          callHotelList();
        })
        .catch((error) => {
          setLoader(false);
          setError("");
          setErrorBilling("");
          console.log(error.response, "create error");
          // setApiError(error.response.data.message);
          notifyError(error.response?.data?.responseMessage);
        });
    }
  };

  function getGroup() {
    getGroupId()
      .then((response) => {
        console.log(response, "ppp");
        setGroupName(response.data.result);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  useEffect(() => {
    getGroup();
  }, []);
  const labelMappings = {
    rooms: "Number of rooms",
    billed_devices: "Billed devices",
    spares: "Spares",
    prestage_enrollment: "Prestage Enrollment name",
    jamf_Site_name: "Site Name",
    jamf_building_name: "Building Name",
    pms_id: "Hotel PMS ID",
    group_id: "Group name",
    billing_firstname: "First name",

    billing_lastname: "Last name",
    billing_phone_number: "Phone number",
    billing_email: "Email",
    gst: "GST",
    lat: "LAT",
    long: "LONG",
  };
  const handleModalClose = () => {
    setAddHotelGroupShow(false);
  };
  return (
    <>
      <Modal className="modal fade" show={addPropertiesModelShow} size="lg">
        <div className="" role="document">
          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  {editMode ? "Edit Property" : "Create Property"}
                </h4>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-dismiss="modal"
                  onClick={() => onHide()}
                >
                  <span>
                    <strong>X</strong>
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div className="add-contact-box">
                  {requiredErrors && (
                    <div className="text-danger fs-12">{requiredErrors}</div>
                  )}
                  <div className="add-contact-content">
                    <div className="row">
                      {/* Build the hotel */}
                      <div className="col-12">
                        <h5 className="mb-3">Build the hotel</h5>
                        {[
                          "hotel_name",
                          "group_id",
                          "address",
                          "postcode",
                          "suburb",
                          "city",
                          "state",
                          "country",
                          "lat",
                          "long",
                          "rooms",
                          "billed_devices",
                          "spares",
                          "entity_name",
                          "contact_firstname",
                          "contact_lastname",
                          "contact_number",
                          "contact_email",
                        ].map((key) => (
                          <div className="form-group mb-3" key={key}>
                            <div className="d-flex justify-content-between align-item-center">
                              <label className="text-black font-w500">
                                {labelMappings[key] ||
                                  key
                                    .replace(/_/g, " ")
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                              </label>
                              {!editMode && key === "group_id" && (
                                <button
                                  onClick={() => setAddHotelGroupShow(true)}
                                  className="btn btn-info ml-auto mb-2"
                                >
                                  Create Group
                                </button>
                              )}
                            </div>

                            {/* ... (input field logic) */}
                            {key === "group_id" ? (
                              <>
                                <select
                                  className="form-control"
                                  name={key}
                                  value={formState[key]}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Select group name</option>
                                  {groupName?.map((item, i) => (
                                    <option key={i} value={item.id}>
                                      {` ${item.group_name}`}
                                    </option>
                                  ))}
                                </select>
                              </>
                            ) : key === "rooms" || key === "spares" ? (
                              <input
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                name={key}
                                value={formState[key]}
                                onChange={handleChange}
                                placeholder={`Enter ${
                                  labelMappings[key] ||
                                  key
                                    .replace(/_/g, " ")
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")
                                }`}
                                min={0}
                                required
                              />
                            ) : key === "contact_number" ? (
                              <PhoneInput
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  fontSize: "16px",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                }}
                                placeholder="Enter phone number"
                                value={selectedCountryCode}
                                onChange={handleCountryCodeChange}
                                defaultCountry="US"
                              />
                            ) : key === "contact_email" ||
                              key === "billing_email" ? (
                              <input
                                type="email"
                                className="form-control"
                                autoComplete="off"
                                name={key}
                                value={formState[key]}
                                onChange={handleChange}
                                placeholder={`Enter ${
                                  labelMappings[key] ||
                                  key
                                    .replace(/_/g, " ")
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")
                                }`}
                                required
                              />
                            ) : key === "address" ? (
                              <GooglePlacesAutocomplete
                                className="form-control"
                                apiKey="AIzaSyA_kz_OxgMPwWS7AEWRVUHSLSvqYFlgZAc"
                                selectProps={{
                                  placeholder: "Enter Address",

                                  // value: googleAddress,
                                  value: {
                                    label: googleAddress,
                                    value: googleAddress,
                                  },

                                  onChange: (place) => {
                                    handleAddress(place);
                                  },
                                }}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name={key}
                                value={formState[key]}
                                onChange={handleChange}
                                placeholder={`Enter ${
                                  labelMappings[key] ||
                                  key
                                    .replace(/_/g, " ")
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")
                                }`}
                                required
                              />
                            )}
                            {key === "contact_number" && error && (
                              <div className="text-danger fs-12">{error}</div>
                            )}
                            {key === "address" && errorAddress && (
                              <div className="text-danger fs-12">
                                {errorAddress}
                              </div>
                            )}
                            {key === "tax_number" && errorTaxNumber && (
                              <div className="text-danger fs-12">
                                {errorTaxNumber}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>

                      {/* Connection details */}
                      <div className="col-12 mt-4">
                        <h5 className="mb-3">Connection details</h5>
                        {[
                          "prestage_enrollment",
                          "jamf_Site_name",
                          "jamf_building_name",
                          "pms_id",
                        ].map((key) => (
                          <div className="form-group mb-3" key={key}>
                            <label className="text-black font-w500">
                              {labelMappings[key] ||
                                key
                                  .replace(/_/g, " ")
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                            </label>
                            {/* ... (input field logic) */}
                            {key === "pms_id" ? (
                              <input
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                name={key}
                                value={formState[key]}
                                onChange={handleChange}
                                placeholder={`Enter ${
                                  labelMappings[key] ||
                                  key.replace(/_/g, " ").toLowerCase()
                                }`}
                                min={0}
                                required
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name={key}
                                value={formState[key]}
                                onChange={handleChange}
                                placeholder={`Enter ${
                                  labelMappings[key] ||
                                  key.replace(/_/g, " ").toLowerCase()
                                }`}
                                required
                              />
                            )}
                          </div>
                        ))}
                      </div>

                      {/* Billing info */}
                      <div className="col-12 mt-4">
                        <h5 className="mb-3">Billing details</h5>
                        {!editMode && (
                          <div className="d-flex mb-3 align-items-center">
                            <label className="mb-0 mr-3 text-black font-w500">
                              Do you want to enable billing from the group ?{" "}
                            </label>
                            <Switch
                              onChange={handleChangeSwitch}
                              checked={checked}
                              className="react-switch"
                            />
                          </div>
                        )}

                        {[
                          "billing_firstname",
                          "billing_lastname",
                          "billing_phone_number",
                          "billing_email",
                          "tax_number",
                          "gst",
                        ].map((key) => (
                          <div className="form-group mb-3" key={key}>
                            <label className="text-black font-w500">
                              {labelMappings[key] ||
                                key
                                  .replace(/_/g, " ")
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                            </label>
                            {key === "billing_phone_number" ? (
                              <>
                                <PhoneInput
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    fontSize: "16px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                  }}
                                  placeholder="Enter phone number"
                                  value={selectedFinanceCountryCode}
                                  onChange={handleFinanceCountryCodeChange}
                                  defaultCountry="US"
                                />
                              </>
                            ) : key === "billing_email" ? (
                              <input
                                type="email"
                                className="form-control"
                                autoComplete="off"
                                name={key}
                                value={formState[key]}
                                onChange={handleChange}
                                placeholder={`Enter ${
                                  labelMappings[key] ||
                                  key.replace(/_/g, " ").toLowerCase()
                                }`}
                                required
                              />
                            ) : key === "tax_number" ? (
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name={key}
                                value={formState[key]}
                                onChange={handleChange}
                                placeholder={`Enter ${
                                  labelMappings[key] ||
                                  key.replace(/_/g, " ").toLowerCase()
                                }`}
                              />
                            ) : key === "gst" ? (
                              <Switch
                                onChange={handleGst}
                                checked={gst}
                                className="react-switch"
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name={key}
                                value={formState[key]}
                                onChange={handleChange}
                                placeholder={`Enter ${
                                  labelMappings[key] ||
                                  key.replace(/_/g, " ").toLowerCase()
                                }`}
                                required
                              />
                            )}

                            {key === "billing_phone_number" && errorBilling && (
                              <div className="text-danger fs-12">
                                {errorBilling}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  {editMode ? "Update" : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                  disabled={loader}
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {addHotelGroupShow && (
        <CreateHotelGroup
          addHotelOwnerShow={addHotelGroupShow}
          onHide={handleModalClose}
          callgrouplist={getGroup}
          editMode={false}
          groupData=""
        />
      )}
    </>
  );
}

export default CreateProperty;
