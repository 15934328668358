import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { Table, Row, Col } from "react-bootstrap";
function PermissionModal({ addHotelOwnerShow, onHide, permission }) {
  const [rolesData, setRolesData] = useState(permission);

  return (
    <>
      <Modal className="modal fade" show={addHotelOwnerShow}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">View Permission</h4>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-dismiss="modal"
                  onClick={() => onHide()}
                >
                  <span>
                    {" "}
                    <strong>X</strong>{" "}
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="pl-2 pr-0"
                      >
                        <Table
                          responsive
                          className="custom-table screen-table roles-table"
                        >
                          <thead>
                            <tr>
                              <th>Permission</th>
                              <th>Add</th>
                              <th>Edit</th>
                              <th>Delete</th>
                              <th>View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(rolesData.ADMIN).map((section) => (
                              <tr key={section}>
                                <td>{section}</td>
                                <td>
                                  <input
                                    className={`day-checkbox`}
                                    type="checkbox"
                                    id={`checkbox-ADMIN-${section}-add`}
                                    checked={rolesData.ADMIN[section].add}
                                  />
                                </td>
                                <td>
                                  <input
                                    className={`day-checkbox`}
                                    type="checkbox"
                                    id={`checkbox-ADMIN-${section}-edit`}
                                    checked={rolesData.ADMIN[section].edit}
                                  />
                                </td>
                                <td>
                                  <input
                                    className={`day-checkbox`}
                                    type="checkbox"
                                    id={`checkbox-ADMIN-${section}-delete`}
                                    checked={rolesData.ADMIN[section].delete}
                                  />
                                </td>

                                <td>
                                  <input
                                    className={`day-checkbox`}
                                    type="checkbox"
                                    id={`checkbox-ADMIN-${section}-view`}
                                    checked={rolesData.ADMIN[section].view}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PermissionModal;
