import React, { useEffect, useState } from "react";

import { Button, Modal } from "react-bootstrap";

function Delete({ show, onHide, callbackfunction }) {
  return (
    <>
      {/* <Modal className="modal fade" show={show}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  Are you sure you want to delete
                </h4>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box"></div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  // onClick={onChangePassword}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal> */}
      <Modal className="fade" show={show}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onHide()} variant="light">
            Close
          </Button>
          <Button variant="danger" onClick={() => callbackfunction()}>
            Yes Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Delete;
