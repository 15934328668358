/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";
import dashbordIcon from "../../../images/dashboard 1.svg";
import userIcon from "../../../images/users.svg";
import articalIcon from "../../../images/banners.svg";
import notifiIcon from "../../../images/bell 1.svg";
import reportIcon from "../../../images/report-copy.svg";
import policyIcon from "../../../images/Policies.svg";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        "analytics",
        "events",
        "order-list",
        "general-customers",
        "reviews",
        "task",
      ],
      users = ["user-management"],
      hotelOwner = ["hotel-owner"],
      propertyListing = ["property-listing"],
      hotelGroup = ["hotel-group"],
      hotelAdmin = ["hotel-admin"],
      artical = ["article-management", "advertise-management"],
      reports = ["reports"],
      notification = ["notifications"],
      policy = ["upload-policy"],
      app = [
        "app-profile",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "post-details",
        "ecom-product-detail",
      ],
      pages = [
        "page-register",
        "page-login",
        "page-lock-screen",
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/">
                {/* <i className="flaticon-381-networking"></i> */}
                <img src={dashbordIcon} />
                <span className="nav-text pl-4">Dashboard</span>
              </Link>
            </li>

            <li className={`${hotelOwner.includes(path) ? "mm-active" : ""}`}>
              <Link to="hotel-owner" className="ai-icon">
                {/* <i className="flaticon-381-settings-2"></i> */}
                <img src={userIcon} />
                <span className="nav-text pl-4">Admin/Hotel Owner</span>
              </Link>
            </li>
            <li
              className={`${propertyListing.includes(path) ? "mm-active" : ""}`}
            >
              <Link to="property-listing" className="ai-icon">
                {/* <i className="flaticon-381-settings-2"></i> */}
                <img src={userIcon} />
                <span className="nav-text pl-4">Property Listing</span>
              </Link>
            </li>
            <li className={`${hotelGroup.includes(path) ? "mm-active" : ""}`}>
              <Link to="hotel-group" className="ai-icon">
                {/* <i className="flaticon-381-settings-2"></i> */}
                <img src={userIcon} />
                <span className="nav-text pl-4">Hotel Group Listing</span>
              </Link>
            </li>

            {/* <li>
              <Link to="detail-page" className="ai-icon">
                <img src={userIcon} />
                <span className="nav-text pl-4">details</span>
              </Link>
            </li> */}

            <li>
              <Link to="category" className="ai-icon">
                {/* <i className="flaticon-381-settings-2"></i> */}
                <img src={userIcon} />
                <span className="nav-text pl-4">Category</span>
              </Link>
            </li>

            {/* <li className={`${hotelAdmin.includes(path) ? "mm-active" : ""}`}>
              <Link to="hotel-admin" className="ai-icon">
            
                <img src={userIcon} />
                <span className="nav-text pl-4">Hotel Admin</span>
              </Link>
            </li> */}

            {/* <li className={`${artical.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                {/* <i className="flaticon-381-television"></i> *
                <img src={articalIcon} ></img>
                <span className="nav-text pl-4">CMS</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "article-management" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/article-management"
                  >
                    Article Management
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "advertise-management" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/advertise-management"
                  >
                    Advertise Management
                  </Link>
                </li>
              </ul>
            </li>
          
            <li className={`${notification.includes(path) ? "mm-active" : ""}`}>
              <Link to="notifications" className="ai-icon" >
                {/* <i className="flaticon-381-settings-2"></i> *
                <img src={notifiIcon}/>
                <span className="nav-text pl-4">Notification</span>
              </Link>
            </li>
            <li className={`${policy.includes(path) ? "mm-active" : ""}`}>
              <Link to="upload-policy" className="ai-icon" >
                {/* <i className="flaticon-381-settings-2"></i> *
                <img src={policyIcon} style={{ width: "25px" }}/>
                <span className="nav-text pl-4">Policy</span>
              </Link>
            </li> */}
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
