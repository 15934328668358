// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import { Modal } from "react-bootstrap";
// import swal from "sweetalert";
// //import logo from '../../images/logo-full.png'
// import { ToastContainer, toast } from "react-toastify";
// import { changePasswoard, createHotelOwner } from "../../services/AuthService";
// import Select from "react-select";
// import { Table, Row, Col } from "react-bootstrap";
// import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

// import "react-phone-number-input/style.css";


// function CreateHotelOwner({ addHotelOwnerShow, onHide, hotelOwnerListCall }) {
//   //Modal box
//   let errorsObj = {
//     userType: "",
//     name: "",
//     lname: "",
//     email: "",

//     country: "",
//     timeZone: "",
//     selectedCountryCode: "",
//   };
//   const [errors, setErrors] = useState(errorsObj);
//   const [name, setName] = useState("");
//   const [lname, setLname] = useState("");

//   const [email, setEmail] = useState("");
//   const [country, setCountry] = useState("");
//   const [loader, setLoader] = useState(false);
//   const [selectedOption, setSelectedOption] = useState("");
//   const [selectedOptionTimeZones, setSelectedOptionTimeZones] = useState("");

//   const options = [
//     { value: "sub_admin", label: "Hotel Owner" },
//     { value: "admin", label: "Admin" },
//   ];
//   const timeZones = [
//     { value: "GMT", label: "Greenwich Mean Time (GMT)" },
//     { value: "UTC", label: "Coordinated Universal Time (UTC)" },
//     { value: "America/New_York", label: "Eastern Time (US & Canada)" },
//     { value: "America/Chicago", label: "Central Time (US & Canada)" },
//     { value: "America/Denver", label: "Mountain Time (US & Canada)" },
//     { value: "America/Los_Angeles", label: "Pacific Time (US & Canada)" },
//     { value: "Europe/London", label: "London, UK" },
//     { value: "Europe/Paris", label: "Paris, France" },
//     { value: "Europe/Berlin", label: "Berlin, Germany" },
//     { value: "Europe/Rome", label: "Rome, Italy" },
//     { value: "Asia/Tokyo", label: "Tokyo, Japan" },
//     { value: "Asia/Shanghai", label: "Shanghai, China" },
//     { value: "Asia/Kolkata", label: "Mumbai, India" },
//     { value: "Australia/Sydney", label: "Sydney, Australia" },
//     { value: "Pacific/Auckland", label: "Auckland, New Zealand" },
//     { value: "Africa/Cairo", label: "Cairo, Egypt" },
//     { value: "Africa/Johannesburg", label: "Johannesburg, South Africa" },
//     { value: "America/Mexico_City", label: "Mexico City, Mexico" },
//     { value: "America/Toronto", label: "Toronto, Canada" },
//     { value: "America/Buenos_Aires", label: "Buenos Aires, Argentina" },
//     { value: "Asia/Dubai", label: "Dubai, United Arab Emirates" },
//     { value: "Asia/Singapore", label: "Singapore" },
//     { value: "Asia/Kolkata", label: "Kolkata, India" },
//     { value: "Pacific/Honolulu", label: "Honolulu, Hawaii" },
//   ];
//   const permission = {
//     ADMIN: {
//       HOTEL: {
//         add: false,
//         edit: false,
//         delete: false,
//         view: false,
//       },
//       HOTEL_OWNER: {
//         add: false,
//         edit: false,
//         delete: false,
//         view: false,
//       },
//       GROUP: {
//         add: false,
//         edit: false,
//         delete: false,
//         view: false,
//       },
//     },
//   };
//   const [rolesData, setRolesData] = useState(permission);

//   const [selectedCountryCode, setSelectedCountryCode] = useState("");
//   console.log(selectedCountryCode, "oo");

//   const handleCountryCodeChange = (event) => {
//     console.log(event, "llll");
//     setSelectedCountryCode(event);
//     // setIsValid(isValidPhoneNumber(event));
//   };

//   console.log(rolesData, "qq");

//   const handleChange = (e, set, section, type, val) => {
//     setRolesData((prevRolesData) => ({
//       ...prevRolesData,
//       [set]: {
//         ...prevRolesData[set],
//         [section]: {
//           ...prevRolesData[set][section],
//           [type]: val,
//         },
//       },
//     }));

//     // setMakeRefresh(true);
//   };
 

//   const handleSelectChange = (selectedOption) => {
//     setSelectedOption(selectedOption);
//   };
//   const handleSelectChangeTimeZone = (timeZones) => {
//     setSelectedOptionTimeZones(timeZones);
//   };
//   const notifyTopRight = (success) => {
//     toast.success(`✅ ${success} !`, {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//     });
//   };
//   const notifyError = (error) => {
//     toast.error(`❌${error}`, {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//     });
//   };
//   function onAddHotelOwner(e) {
//     e.preventDefault();
//     setLoader(true);
//     let error = false;
//     const errorObj = { ...errorsObj };
//     if (selectedOption === "") {
//       errorObj.userType = "User Type is Required";
//       error = true;
//     }
//     if (name.trim() === "") {
//       errorObj.name = "First Name is Required";
//       error = true;
//     }
//     if (lname.trim() === "") {
//       errorObj.lname = "Last Name is Required";
//       error = true;
//     }
//     if (country.trim() === "") {
//       errorObj.country = "Country Name is Required";
//       error = true;
//     }
//     if (email.trim() === "") {
//       errorObj.email = "Email is Required";
//       error = true;
//     }

//     if (selectedOptionTimeZones === "") {
//       errorObj.timeZone = "Time Zone is Required";
//       error = true;
//     }

//     if (selectedCountryCode.trim() === "") {
//       errorObj.selectedCountryCode = "Phone number is Required";
//       error = true;
//     }

//     if (selectedCountryCode.trim().length > 15) {
//       errorObj.selectedCountryCode = "Phone no. must be less than 15";
//       error = true;
//     }

//     if (selectedCountryCode.trim().length < 6) {
//       errorObj.selectedCountryCode = "Phone no. must be 6";
//       error = true;
//     }

//     setErrors(errorObj);
//     if (error) {
//       setLoader(false);
//       return;
//     }

//     createHotelOwner(
//       email.trim(),
//       name.trim(),
//       lname.trim(),
//       country.trim(),
//       selectedOptionTimeZones.value,
//       selectedOption.value,
//       selectedCountryCode,
//       rolesData
//     )
//       .then((response) => {
//         console.log(response, "oo");
//         setName("");
//         setLname("");
//         setCountry("");
//         setSelectedOptionTimeZones("");
//         setSelectedOption("");
//         setSelectedCountryCode("");
//         hotelOwnerListCall();
//         setLoader(false);
//         onHide();
//         notifyTopRight(response.data.responseMessage);
//       })
//       .catch((error) => {
//         setLoader(false);
//         console.log(error.response, "change password error");
//         // setApiError(error.response.data.message);
//         notifyError(error.response.data.responseMessage);
//         console.log(error.response.data.responseMessage, "pp");
//       });
//   }
//   return (
//     <>
//       <Modal className="modal fade" show={addHotelOwnerShow}>
//         <div className="" role="document">
//           <div className="">
//             <form onSubmit={onAddHotelOwner}>
//               <div className="modal-header">
//                 <h4 className="modal-title fs-20">
//                   Create Hotel Owner / Admin
//                 </h4>
//                 {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
//                 <button
//                   type="button"
//                   className="btn-close bg-white"
//                   data-dismiss="modal"
//                   onClick={() => onHide()}
//                 >
//                   <span>
//                     {" "}
//                     <strong>X</strong>{" "}
//                   </span>
//                 </button>
//               </div>
//               <div className="modal-body">
//                 <i
//                   className="flaticon-cancel-12 close"
//                   data-dismiss="modal"
//                 ></i>
//                 <div className="add-contact-box">
//                   <div className="add-contact-content">
//                     <div className="form-group">
//                       <label className="text-black font-w500">
//                         Select User Type
//                       </label>
//                       <Select
//                         value={selectedOption}
//                         onChange={handleSelectChange}
//                         options={options}
//                         required={true}
//                         isSearchable={false}
//                       />
//                       {errors.userType && (
//                         <div className="text-danger fs-12">
//                           {errors.userType}
//                         </div>
//                       )}
//                     </div>
//                     <div className="form-group mb-3">
//                       <label className="text-black font-w500">First Name</label>
//                       <div className="contact-name">
//                         <input
//                           type="test"
//                           className="form-control"
//                           autocomplete="off"
//                           name="name"
//                           value={name}
//                           onChange={(e) => setName(e.target.value)}
//                           placeholder="Enter your Name"
//                           required
//                         />
//                         <span className="validation-text"></span>
//                         {errors.name && (
//                           <div className="text-danger fs-12">{errors.name}</div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="form-group mb-3">
//                       <label className="text-black font-w500">Last Name</label>
//                       <div className="contact-name">
//                         <input
//                           type="test"
//                           className="form-control"
//                           autocomplete="off"
//                           name="lastname"
//                           value={lname}
//                           onChange={(e) => setLname(e.target.value)}
//                           placeholder="Enter your Last Name"
//                           required
//                         />
//                         <span className="validation-text"></span>
//                         {errors.lname && (
//                           <div className="text-danger fs-12">
//                             {errors.lname}
//                           </div>
//                         )}
//                       </div>
//                     </div>

//                     <div className="form-group mb-3">
//                       <label className="text-black font-w500">Email</label>
//                       <div className="contact-name">
//                         <input
//                           type="email"
//                           className="form-control"
//                           autocomplete="off"
//                           name="email"
//                           value={email}
//                           onChange={(e) => setEmail(e.target.value)}
//                           placeholder="Enter your Email"
//                           required
//                         />
//                         <span className="validation-text"></span>
//                         {errors.email && (
//                           <div className="text-danger fs-12">
//                             {errors.email}
//                           </div>
//                         )}
//                       </div>
//                     </div>

//                     <div className="form-group mb-3">
//                       <label className="text-black font-w500">
//                         Phone number
//                       </label>
//                       <PhoneInput
//                         style={{
//                           width: "100%",
//                           padding: "10px",
//                           fontSize: "16px",
//                           border: "1px solid #ccc",
//                           borderRadius: "5px",
//                         }}
//                         placeholder="Enter phone number"
//                         value={selectedCountryCode}
//                         onChange={handleCountryCodeChange}
//                         defaultCountry="US"
//                       />
//                       {errors.selectedCountryCode && (
//                         <div className="text-danger fs-12">
//                           {errors.selectedCountryCode}
//                         </div>
//                       )}
//                     </div>

//                     <div className="form-group mb-3">
//                       <label className="text-black font-w500">
//                         Country name
//                       </label>
//                       <div className="contact-name">
//                         <input
//                           type="text"
//                           className="form-control"
//                           autocomplete="off"
//                           name="properties"
//                           value={country}
//                           onChange={(e) => setCountry(e.target.value)}
//                           placeholder="Enter Country"
//                           required
//                         />
//                         <span className="validation-text"></span>
//                         {errors.country && (
//                           <div className="text-danger fs-12">
//                             {errors.country}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                     <div className="form-group">
//                       <label className="text-black font-w500">
//                         Select Time Zone
//                       </label>
//                       <Select
//                         value={selectedOptionTimeZones}
//                         onChange={handleSelectChangeTimeZone}
//                         options={timeZones}
//                         required={true}
//                       />
//                       {errors.timeZone && (
//                         <div className="text-danger fs-12">
//                           {errors.timeZone}
//                         </div>
//                       )}
//                     </div>
//                     {selectedOption.value === "admin" && (
//                       <div>
//                         <Col
//                           lg={12}
//                           md={12}
//                           sm={12}
//                           xs={12}
//                           className="pl-2 pr-0"
//                         >
//                           <Table
//                             responsive
//                             className="custom-table screen-table roles-table"
//                           >
//                             <thead>
//                               <tr>
//                                 <th>Permission</th>

//                                 <th>Add</th>
//                                 <th>edit</th>
//                                 <th>Delete</th>
//                                 <th>View</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {Object.keys(rolesData.ADMIN).map((section) => (
//                                 <tr key={section}>
//                                   <td>{section}</td>
//                                   <td>
//                                     <input
//                                       className={`day-checkbox`}
//                                       type="checkbox"
//                                       id={`checkbox-ADMIN-${section}-add`}
//                                       onChange={(e) =>
//                                         handleChange(
//                                           e,
//                                           "ADMIN",
//                                           section,
//                                           "add",
//                                           e.target.checked
//                                         )
//                                       }
//                                       checked={rolesData.ADMIN[section].add}
//                                     />
//                                   </td>
//                                   <td>
//                                     <input
//                                       className={`day-checkbox`}
//                                       type="checkbox"
//                                       id={`checkbox-ADMIN-${section}-edit`}
//                                       onChange={(e) =>
//                                         handleChange(
//                                           e,
//                                           "ADMIN",
//                                           section,
//                                           "edit",
//                                           e.target.checked
//                                         )
//                                       }
//                                       checked={rolesData.ADMIN[section].edit}
//                                     />
//                                   </td>
//                                   <td>
//                                     <input
//                                       className={`day-checkbox`}
//                                       type="checkbox"
//                                       id={`checkbox-ADMIN-${section}-delete`}
//                                       onChange={(e) =>
//                                         handleChange(
//                                           e,
//                                           "ADMIN",
//                                           section,
//                                           "delete",
//                                           e.target.checked
//                                         )
//                                       }
//                                       checked={rolesData.ADMIN[section].delete}
//                                     />
//                                   </td>
//                                   <td>
//                                     <input
//                                       className={`day-checkbox`}
//                                       type="checkbox"
//                                       id={`checkbox-ADMIN-${section}-view`}
//                                       onChange={(e) =>
//                                         handleChange(
//                                           e,
//                                           "ADMIN",
//                                           section,
//                                           "view",
//                                           e.target.checked
//                                         )
//                                       }
//                                       checked={rolesData.ADMIN[section].view}
//                                     />
//                                   </td>
//                                 </tr>
//                               ))}
//                             </tbody>
//                           </Table>
//                         </Col>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="submit"
//                   className="btn btn-primary"
//                   // onClick={onChangePassword}
//                   disabled={loader}
//                 >
//                   Submit
//                 </button>
//                 <button
//                   type="button"
//                   onClick={() => onHide()}
//                   className="btn btn-danger"
//                 >
//                   {" "}
//                   <i className="flaticon-delete-1"></i> Cancel
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// }

// export default CreateHotelOwner;





import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { createHotelOwner } from "../../services/AuthService";
import { Table, Row, Col } from "react-bootstrap";

function CreateHotelOwner({ addHotelOwnerShow, onHide, hotelOwnerListCall }) {
  let errorsObj = {
    userType: "",
    name: "",
    lname: "",
    email: "",
    country: "",
    timeZone: "",
    selectedCountryCode: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [name, setName] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionTimeZones, setSelectedOptionTimeZones] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const permission = {
    ADMIN: {
      HOTEL: {
        add: false,
        edit: false,
        delete: false,
        view: false,
      },
      HOTEL_OWNER: {
        add: false,
        edit: false,
        delete: false,
        view: false,
      },
      GROUP: {
        add: false,
        edit: false,
        delete: false,
        view: false,
      },
    },
  };
  const [rolesData, setRolesData] = useState(permission);

  const options = [
    { value: "hotel_owner", label: "Hotel Owner" },
    { value: "admin", label: "Admin" },
  ];

  const timeZones = [
    { value: "GMT", label: "Greenwich Mean Time (GMT)" },
    { value: "UTC", label: "Coordinated Universal Time (UTC)" },
    { value: "America/New_York", label: "Eastern Time (US & Canada)" },
    { value: "America/Chicago", label: "Central Time (US & Canada)" },
    { value: "America/Denver", label: "Mountain Time (US & Canada)" },
    { value: "America/Los_Angeles", label: "Pacific Time (US & Canada)" },
    { value: "Europe/London", label: "London, UK" },
    { value: "Europe/Paris", label: "Paris, France" },
    { value: "Europe/Berlin", label: "Berlin, Germany" },
    { value: "Europe/Rome", label: "Rome, Italy" },
    { value: "Asia/Tokyo", label: "Tokyo, Japan" },
    { value: "Asia/Shanghai", label: "Shanghai, China" },
    { value: "Asia/Kolkata", label: "Mumbai, India" },
    { value: "Australia/Sydney", label: "Sydney, Australia" },
    { value: "Pacific/Auckland", label: "Auckland, New Zealand" },
    { value: "Africa/Cairo", label: "Cairo, Egypt" },
    { value: "Africa/Johannesburg", label: "Johannesburg, South Africa" },
    { value: "America/Mexico_City", label: "Mexico City, Mexico" },
    { value: "America/Toronto", label: "Toronto, Canada" },
    { value: "America/Buenos_Aires", label: "Buenos Aires, Argentina" },
    { value: "Asia/Dubai", label: "Dubai, United Arab Emirates" },
    { value: "Asia/Singapore", label: "Singapore" },
    { value: "Asia/Kolkata", label: "Kolkata, India" },
    { value: "Pacific/Honolulu", label: "Honolulu, Hawaii" },
  ];

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success} !`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const notifyError = (error) => {
    toast.error(`❌ ${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const clearErrors = (field) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleChange = (e, setField, fieldName) => {
    setField(e.target.value);
    clearErrors(fieldName);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    clearErrors("userType");
  };

  const handleSelectChangeTimeZone = (timeZones) => {
    setSelectedOptionTimeZones(timeZones);
    clearErrors("timeZone");
  };

  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event);
    clearErrors("selectedCountryCode");
  };

  function onAddHotelOwner(e) {
    e.preventDefault();
    setLoader(true);

    let error = false;
    const errorObj = { ...errorsObj };

    if (selectedOption === "") {
      errorObj.userType = "User Type is Required";
      error = true;
    }
    if (name.trim() === "") {
      errorObj.name = "First Name is Required";
      error = true;
    }
    if (lname.trim() === "") {
      errorObj.lname = "Last Name is Required";
      error = true;
    }
    if (country.trim() === "") {
      errorObj.country = "Country Name is Required";
      error = true;
    }
    if (email.trim() === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (selectedOptionTimeZones === "") {
      errorObj.timeZone = "Time Zone is Required";
      error = true;
    }
    if (selectedCountryCode.trim() === "") {
      errorObj.selectedCountryCode = "Phone number is Required";
      error = true;
    }
    if (selectedCountryCode.trim().length > 15) {
      errorObj.selectedCountryCode = "Phone number must be less than 15 characters";
      error = true;
    }
    if (selectedCountryCode.trim().length < 6) {
      errorObj.selectedCountryCode = "Phone number must be at least 6 characters";
      error = true;
    }

    setErrors(errorObj);

    if (error) {
      setLoader(false);
      return;
    }

    createHotelOwner(
      email.trim(),
      name.trim(),
      lname.trim(),
      country.trim(),
      selectedOptionTimeZones.value,
      selectedOption.value,
      selectedCountryCode
    )
      .then((response) => {
        setName("");
        setLname("");
        setCountry("");
        setSelectedOptionTimeZones("");
        setSelectedOption("");
        setSelectedCountryCode("");
        hotelOwnerListCall();
        setLoader(false);
        onHide();
        notifyTopRight(response?.data?.responseMessage);
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error?.response?.data?.responseMessage);
      });
  }

  return (
    <>
      <Modal className="modal fade" show={addHotelOwnerShow}>
        <div className="" role="document">
          <div className="">
            <form onSubmit={onAddHotelOwner}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  Create Hotel Owner / Admin
                </h4>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-dismiss="modal"
                  onClick={() => onHide()}
                >
                  <span>
                    {" "}
                    <strong>X</strong>{" "}
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group">
                      <label className="text-black font-w500">Select User Type</label>
                      <Select
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={options}
                        required
                        isSearchable={false}
                      />
                      {errors.userType && (
                        <div className="text-danger fs-12">{errors.userType}</div>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">First Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="name"
                          value={name}
                          onChange={(e) => handleChange(e, setName, "name")}
                          placeholder="Enter your First Name"
                          required
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Last Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="lname"
                          value={lname}
                          onChange={(e) => handleChange(e, setLname, "lname")}
                          placeholder="Enter your Last Name"
                          required
                        />
                        {errors.lname && (
                          <div className="text-danger fs-12">{errors.lname}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          className="form-control"
                          autoComplete="off"
                          name="email"
                          value={email}
                          onChange={(e) => handleChange(e, setEmail, "email")}
                          placeholder="Enter your Email"
                          required
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Phone number</label>
                      <PhoneInput
                        style={{
                          width: "100%",
                          padding: "10px",
                          fontSize: "16px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                        }}
                        placeholder="Enter phone number"
                        value={selectedCountryCode}
                        onChange={handleCountryCodeChange}
                        defaultCountry="US"
                      />
                      {errors.selectedCountryCode && (
                        <div className="text-danger fs-12">{errors.selectedCountryCode}</div>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Country Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="country"
                          value={country}
                          onChange={(e) => handleChange(e, setCountry, "country")}
                          placeholder="Enter Country"
                          required
                        />
                        {errors.country && (
                          <div className="text-danger fs-12">{errors.country}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">Select Time Zone</label>
                      <Select
                        value={selectedOptionTimeZones}
                        onChange={handleSelectChangeTimeZone}
                        options={timeZones}
                        required
                      />
                      {errors.timeZone && (
                        <div className="text-danger fs-12">{errors.timeZone}</div>
                      )}
                    </div>
               
                    {selectedOption.value === "admin" && (
                      <div>
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="pl-2 pr-0"
                        >
                          <Table
                            responsive
                            className="custom-table screen-table roles-table"
                          >
                            <thead>
                              <tr>
                                <th>Permission</th>

                                <th>Add</th>
                                <th>edit</th>
                                <th>Delete</th>
                                <th>View</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(rolesData.ADMIN).map((section) => (
                                <tr key={section}>
                                  <td>{section}</td>
                                  <td>
                                    <input
                                      className={`day-checkbox`}
                                      type="checkbox"
                                      id={`checkbox-ADMIN-${section}-add`}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          "ADMIN",
                                          section,
                                          "add",
                                          e.target.checked
                                        )
                                      }
                                      checked={rolesData.ADMIN[section].add}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className={`day-checkbox`}
                                      type="checkbox"
                                      id={`checkbox-ADMIN-${section}-edit`}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          "ADMIN",
                                          section,
                                          "edit",
                                          e.target.checked
                                        )
                                      }
                                      checked={rolesData.ADMIN[section].edit}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className={`day-checkbox`}
                                      type="checkbox"
                                      id={`checkbox-ADMIN-${section}-delete`}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          "ADMIN",
                                          section,
                                          "delete",
                                          e.target.checked
                                        )
                                      }
                                      checked={rolesData.ADMIN[section].delete}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className={`day-checkbox`}
                                      type="checkbox"
                                      id={`checkbox-ADMIN-${section}-view`}
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          "ADMIN",
                                          section,
                                          "view",
                                          e.target.checked
                                        )
                                      }
                                      checked={rolesData.ADMIN[section].view}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loader}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default CreateHotelOwner;

